import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/theme',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/server',
    name: 'server',
    component: () => import('../views/ServerView.vue')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/CaseView.vue')
  },
  {
    path: '/caseDetail',
    name: 'caseDetail',
    component: () => import('../views/CaseDetailView.vue')
  },
  {
    path: '/channel',
    name: 'channel',
    component: () => import('../views/ChannelView.vue')
  },
  {
    path: '/industry',
    name: 'industry',
    component: () => import('../views/IndustryView.vue')
  },
  {
    path: '/industryDetail',
    name: 'industryDetail',
    component: () => import('../views/IndustryDetailView.vue')
  },
  {
    path: '/update',
    name: 'update',
    component: () => import('../views/UpdateView.vue')
  },
  {
    path: '/',
    name: 'theme',
    component: () => import('../views/theme/HomeView.vue')
  },
  {
    path: '/WeCode',
    name: 'WeCode',
    component: () => import('../views/theme/WeCodeView.vue')
  },
  {
    path: '/WePlatform',
    name: 'WePlatform',
    component: () => import('../views/theme/WePlatformView.vue')
  },
  {
    path: '/WeAgent',
    name: 'WeAgent',
    component: () => import('../views/theme/WeAgentView.vue')
  },
  {
    path: '/WeSCRM',
    name: 'WeSCRM',
    component: () => import('../views/theme/WeSCRMView.vue')
  },
  {
    path: '/WeGlobal',
    name: 'WeGlobal',
    component: () => import('../views/theme/WeXpressView.vue')
  },
  {
    path: '/WeVideo',
    name: 'WeVideo',
    component: () => import('../views/theme/WeVideoView.vue')
  },
  {
    path: '/WeExpert',
    name: 'WeExpert',
    component: () => import('../views/theme/WeExpertView.vue')
  },
  {
    path: '/WeIndustry',
    name: 'WeIndustry',
    component: () => import('../views/theme/WeIndustryView.vue')
  },
  {
    path: '/WeStatute',
    name: 'WeStatute',
    component: () => import('../views/theme/WeStatuteView.vue')
  },
  {
    path: '/WeComm',
    name: 'WeComm',
    component: () => import('../views/theme/WeCommenceView.vue')
  },
  {
    path: '/WeFactory',
    name: 'WeFactory',
    component: () => import('../views/theme/WeFactoryView.vue')
  },
  {
    path: '/WePark',
    name: 'WePark',
    component: () => import('../views/theme/WeParkView.vue')
  },
  {
    path: '/WeAffair',
    name: 'WeAffair',
    component: () => import('../views/theme/WeAffairView.vue')
  },
  {
    path: '/WePartner',
    name: 'WePartner',
    component: () => import('../views/theme/WePartnerView.vue')
  },
  {
    path: '/Certification',
    name: 'Certification',
    component: () => import('../views/theme/WeCertificationView.vue')
  },
  {
    path: '/Senario',
    name: 'Senario',
    component: () => import('../views/theme/WeSenarioView.vue')
  },
  {
    path: '/UpGrade',
    name: 'UpGrade',
    component: () => import('../views/theme/UpGradeView.vue')
  },
  {
    path: '/ai',
    name: 'ai',
    component: () => import('../views/ai/HomeView.vue')
  },
  {
    path: '/ai-pink',
    name: 'ai-pink',
    component: () => import('../views/ai-pink/HomeView.vue')
  },
  {
    path: '/ai-blue',
    name: 'ai-blue',
    component: () => import('../views/ai-blue/HomeView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
